import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Skycons from "react-skycons";
import { SlidingUpcomingWeatherScale } from "./SlidingUpcomingWeatherScale";
import useInterval from "../utils/useInterval";
import config from "../config";
import { AppState } from "../utils/useSettings";

const apiUrl = config.Api.Weather;

async function getForecast(latLong: string) {
  console.log(`${apiUrl}/${latLong}`);
  const result = await axios.get(`${apiUrl}/${latLong}`);
  return result.data;
}

function updateForecast(forecast: any, setForecast: Function) {
  setForecast(forecast);
}

export function fixupIconForSkycons(iconName: string) {
  const iconRegex = /-/gi;
  return iconName.replace(iconRegex, "_").toUpperCase();
}

function roundFraction(temp: number) {
  return Math.round(temp);
}

function nextNHours(data: Array<any>, numHours: number) {
  return data.slice(1, numHours + 1);
}

export function getTemperature(
  actual: number,
  apparent: number,
  realFeel: boolean
) {
  return realFeel ? roundFraction(apparent) : roundFraction(actual);
}

interface WeatherProps {
  settings: AppState;
  onUpdateSettings: Function;
}

function Weather({ settings, onUpdateSettings }: WeatherProps) {
  const [theForecast, setForecast] = useState();
  const [interval] = useState();

  if (interval) {
    clearInterval(interval);
  }

  useInterval(() => {
    if (!settings.latLong) return;
    getForecast(settings.latLong).then(result => {
      updateForecast(result, setForecast);
    });
  }, 96000);

  useEffect(() => {
    if (!settings.latLong) {
      setForecast(null);
      return;
    }
    getForecast(settings.latLong).then(result => {
      updateForecast(result, setForecast);
    });
  }, [settings.latLong]);

  const handleRealFeelClick = () => {
    const newValue = !settings.realFeel;
    const newSettings = { ...settings };
    newSettings.realFeel = newValue;
    onUpdateSettings(newSettings);
  };

  if (!theForecast) return <>{settings.location}</>;

  const { forecast } = theForecast;
  return (
    <div className="forecast">
      <div className="top">
        <div className="current">
          <div className="summary">
            <div className="summary-text" style={{ border: "none" }}>
              {settings.location}
            </div>
            <div className="summary-icon">
              <Skycons
                color="white"
                icon={fixupIconForSkycons(forecast.currently.icon)}
                autoplay={true}
              />
            </div>
            <div className="summary-text">{forecast.currently.summary}</div>
            <div className="row centered">
              <div className="col-center">
                {roundFraction(forecast.currently.humidity * 100)}% <br />
                <div className="note light">humidity</div>
              </div>
              <div className="col-center">
                {roundFraction(forecast.daily.data[0].precipProbability * 100)}%{" "}
                <br />
                <div className="note light">chance of rain</div>
              </div>
            </div>
          </div>
          <div className="temperature">
            <div className="temperature-item">
              <span className="data clickable" onClick={handleRealFeelClick}>
                {getTemperature(
                  forecast.currently.temperature,
                  forecast.currently.apparentTemperature,
                  settings.realFeel
                )}
                <span>˚f</span>
              </span>

              <br />
              <small className="note light">
                {settings.realFeel ? "real feel" : "actual"}
              </small>
            </div>
            <div className="temperature-item">
              <span className="data">
                {getTemperature(
                  forecast.daily.data[0].temperatureHigh,
                  forecast.daily.data[0].apparentTemperatureHigh,
                  settings.realFeel
                )}
              </span>
              <span> / </span>
              <span className="data">
                {getTemperature(
                  forecast.daily.data[0].temperatureLow,
                  forecast.daily.data[0].apparentTemperatureLow,
                  settings.realFeel
                )}
                <span>˚f</span>
              </span>
              <br />
              <small className="note light">High / Low</small>
            </div>
          </div>
        </div>
        <div className="hourly">
          <SlidingUpcomingWeatherScale
            upcomingWeather={nextNHours(forecast.hourly.data, 6)}
            realFeel={settings.realFeel}
          />
        </div>
      </div>
      <div className="future-summary">{forecast.daily.summary}</div>
      <div className="future-forecast">
        {forecast.daily.data.map(day => {
          return (
            <div className="future-day" key={day.time}>
              <div className="future-day-name">
                {moment(day.time * 1000).format("ddd")}
              </div>
              <div className="future-day-forecast">
                <div className="future-day-forecast-icon">
                  <Skycons
                    icon={fixupIconForSkycons(day.icon)}
                    color={"white"}
                  />
                </div>
                <div className="future-day-forecast-temperature">
                  <div className="future-day-forecast-temperature-high">
                    {getTemperature(
                      day.temperatureHigh,
                      day.apparentTemperatureHigh,
                      settings.realFeel
                    )}
                  </div>
                  <div>
                    {getTemperature(
                      day.temperatureLow,
                      day.apparentTemperatureLow,
                      settings.realFeel
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="note">
        <small>
          valid at{" "}
          {moment(forecast.validAt).format("dddd, MMMM Do YYYY hh:mm:ss a")}
        </small>
      </div>
    </div>
  );
}

export default Weather;
