import Axios from "axios";
import config from "../config";

const apiUrl: string = config.Api.Geocode;

export function isLatLong(value: string) {
  var regex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
  return regex.test(value);
}

class Geocoder {
  static loadData = async (url: string) => {
    const data = await Axios.get(url);
    return data;
  };

  static geocode = async (place: string) => {
    console.log('Request to Geocode', place);
    const url = isLatLong(place) ? `${apiUrl}?latLong=${place}` : `${apiUrl}/${place}`;
    console.log("Geocoding:", url);
    try {
      const results = await Geocoder.loadData(url);
      if (results) {
        return results.data;
      }
      return false;
    } catch (er) {
      return {
        coords: false,
        tzid: "America/New_York"
      };
    }
  };
}

export default Geocoder;
