import React from "react";
import moment from "moment";
import Skycons from "react-skycons";
import { getTemperature, fixupIconForSkycons } from "./Weather";

export function SlidingUpcomingWeatherScale({ upcomingWeather, realFeel }) {
  const min = upcomingWeather.reduce((min, curr) => {
    const temp = getTemperature(
      curr.temperature,
      curr.apparentTemperature,
      realFeel
    );
    if (temp < min) return temp;
    return min;
  }, 200);
  const max = upcomingWeather.reduce((max, curr) => {
    const temp = getTemperature(
      curr.temperature,
      curr.apparentTemperature,
      realFeel
    );
    if (temp > max) return temp;
    return max;
  }, 0);
  // The difference between the minimum and the maximum when divided
  //
  // const displayTemps = upcomingWeather.reduce((all, curr) => {
  //   if (all.length === 0) {
  //   }
  // }, []);
  const spread = max - min;
  return upcomingWeather.map(hour => {
    const temperatureValue = getTemperature(
      hour.temperature,
      hour.apparentTemperature,
      realFeel
    );
    const diff = max - temperatureValue;
    const offset = (100 - (diff / spread) * 100) / 2;
    return (
      <div className="hour" key={`hour-${hour.time}`}>
        <div className="icon">
          <Skycons icon={fixupIconForSkycons(hour.icon)} color={"white"} />
        </div>
        <div className="temperature">
          <div className="continuum-line" />
          <div
            className="number-container"
            style={{
              position: "relative",
              left: `${offset}%`
            }}
          >
            <div className="time-container">
              <div className="time">
                {moment(hour.time * 1000).format("hh")}
                <span className="am-pm">
                  {moment(hour.time * 1000).format(" a")}
                </span>
              </div>
            </div>
            <div className="temp-container">
              <div className="temp">
                {getTemperature(
                  hour.temperature,
                  hour.apparentTemperature,
                  realFeel
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
