import React, { useEffect, useState } from "react";
import useInterval from "../utils/useInterval";
import { getNewBuildHash, getOriginalBuildHash } from "../utils/build-hash";

function ReloadOnNewVersion() {
  const [originalHash, setOriginalHash] = useState();
  const [newHash, setNewHash] = useState();

  useInterval(() => {
    getNewBuildHash(setNewHash);
  }, 30000);

  useEffect(() => {
    getOriginalBuildHash(setOriginalHash);
  }, []);

  if (newHash !== originalHash && newHash) {
    window.location.reload();
  }
  return <></>;
}

export default ReloadOnNewVersion;
