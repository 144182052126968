import React, { useState } from "react";
import useInterval from "../utils/useInterval";
import getDateTime from "../utils/date-time";
import { AppState } from "../utils/useSettings";

interface ClockProps {
  showSeconds: boolean;
  settings: AppState;
}

function Clock({ showSeconds, settings: { tzid } }: ClockProps) {
  const [firstDate, firstTime] = getDateTime(
    showSeconds,
    tzid || "America/New_York"
  );
  const [time, setTime] = useState(firstTime);
  const [date, setDate] = useState(firstDate);

  useInterval(
    () => {
      const [date, time] = getDateTime(showSeconds, tzid || "America/New_York");
      setTime(time);
      setDate(date);
    },
    showSeconds ? 1000 : 5000
  );

  return (
    <div className="clock">
      <div className="time">{time}</div>
      <div className="date">{date}</div>
    </div>
  );
}

export default Clock;
