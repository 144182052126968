import React, { useState } from "react";
import Geocoder from "../utils/Geocoder";
import debounce from "debounce-async";
import { AppState } from "../utils/useSettings";

const handleLocationChange = debounce(
  async (value: string, settings: AppState, onUpdate: Function) => {
    const newSettings = { ...settings };
    const { coords, tzid } = await Geocoder.geocode(value);
    newSettings.useLocation = false;
    if (coords === false) {
      newSettings["latLong"] = undefined;
      newSettings["tzid"] = "America/New_York";
      if (
        newSettings.tzid !== settings.tzid ||
        newSettings.latLong !== settings.latLong
      )
        onUpdate({ ...newSettings });
      return;
    }
    if (coords && coords.lat && coords.long) {
      newSettings["latLong"] = `${coords.lat},${coords.long}`;
      newSettings["tzid"] = tzid;
      onUpdate({ ...newSettings });
    }
  },
  1000
);

interface SettingsProps {
  settings: AppState;
  onUpdate: Function;
}

const Settings = ({ settings, onUpdate }: SettingsProps) => {
  const [visible, setVisible] = useState(false);
  const handleChange = async (name: string, value: any) => {
    const newSettings = { ...settings };
    newSettings[name] = value;
    if (name === "location") {
      handleLocationChange(value, newSettings, onUpdate);
    }
    if (name === "useLocation") {
      if (value) handleLocationClick();
    }
    onUpdate(newSettings);
  };

  const handleLocationClick = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async pos => {
        const { coords } = pos;
        console.log("Geolocation complete", coords);
        const newSettings = { ...settings };
        newSettings.useLocation = true;
        newSettings.latLong = `${coords.latitude},${coords.longitude}`;
        const results = await Geocoder.geocode(
          `${coords.longitude},${coords.latitude}`
        );
        newSettings.location = results.text;
        newSettings.tzid = results.tzid;
        onUpdate(newSettings);
      });
    } else {
      alert("We are unable to automatically find your location");
    }
  };

  if (!visible) {
    return (
      <div
        className="subtle-text clickable"
        onClick={() => setVisible(!visible)}
      >
        Settings
      </div>
    );
  }

  return (
    <div className="settings-container">
      <div className="settings">
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              value={settings.location}
              disabled={settings.useLocation}
              onChange={e => handleChange("location", e.target.value)}
            />
            {/* <button className="subtle-button" onClick={handleLocationClick}>
              Find me
            </button> */}
          </div>
          <div className="form-group">
            <label>Use My Location</label>
            <input
              type="checkbox"
              checked={settings.useLocation}
              disabled={!navigator.geolocation}
              onChange={e => handleChange("useLocation", e.target.checked)}
            />
          </div>
          <div className="form-group">
            <label>Real Feel</label>
            <input
              type="checkbox"
              checked={settings.realFeel}
              onChange={e => handleChange("realFeel", e.target.checked)}
            />
          </div>
        </form>
        <div
          className="subtle-button clickable"
          onClick={() => setVisible(false)}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default Settings;
