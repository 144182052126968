import React, { useState } from "react";
import Clock from "./components/Clock";
import Weather from "./components/Weather";
import Settings from "./components/Settings";
import "./App.css";
import useSettings from "./utils/useSettings";
import forceHttps from "./utils/force-https";
import ReloadOnNewVersion from "./components/ReloadOnNewVersion";
import Fullscreen from "react-full-screen";

forceHttps();

function App() {
  const [settings, setSettings] = useSettings();
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <div className="App">
      <Fullscreen
        enabled={fullScreen}
        onChange={isFull => setFullScreen(isFull)}
      >
        <header className="App-header">
          <ReloadOnNewVersion />
          <Clock showSeconds={false} settings={settings} />
          <Weather settings={settings} onUpdateSettings={setSettings} />
          <Settings settings={settings} onUpdate={setSettings} />
        </header>
      </Fullscreen>
    </div>
  );
}

export default App;
