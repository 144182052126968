import { useState, useEffect } from "react";
import Geocoder from "./Geocoder";

export interface AppState {
  location: string | undefined;
  latLong: string | undefined;
  realFeel: boolean;
  tzid: string | null;
  useLocation: boolean;
}

const initialState = {
  location: undefined,
  latLong: undefined,
  realFeel: false,
  tzid: "America/New_York",
  useLocation: false
};

function safeLoadSettings(): AppState {
  const existingSettings = localStorage.getItem("settings");

  try {
    if (existingSettings) return JSON.parse(existingSettings);
  } catch { }
  return initialState;
}

async function handlePositionChange(pos: any, settings: AppState, setSettings: Function) {
  const { coords } = pos;
  console.log("Geolocation:", "Position Changed:", coords);
  const newSettings = { ...settings };
  newSettings.useLocation = true;
  newSettings.latLong = `${coords.latitude},${
    coords.longitude
    }`;
  const results = await Geocoder.geocode(
    `${coords.longitude},${coords.latitude}`
  );
  newSettings.location = results.text;
  newSettings.tzid = results.tzid;
  setSettings(newSettings);
}

function hookWatchPosition(settings, setSettings): number {
  if (!navigator.geolocation) {
    console.log("No navigator.geolocation present. Cannot hook watch position");
    return 0;
  }
  const watchLocation = navigator.geolocation.watchPosition(
    async pos => {
      handlePositionChange(pos, settings, setSettings)
    }
  );
  return watchLocation;
}

function clearWatchPosition(id: number) {
  if (!navigator.geolocation) {
    console.log("No navigator.geolocation present. Cannot clear watch position");
    return;
  }
  navigator.geolocation.clearWatch(id)
}

function useSettings(): [AppState, Function] {
  const [settings, setSettings] = useState(safeLoadSettings);
  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings.location, settings.latLong, settings.realFeel, settings]);

  useEffect(() => {
    if (settings.useLocation) {
      const watchLocation = hookWatchPosition(settings, setSettings);
      return () => clearWatchPosition(watchLocation);
    }
  }, [settings.useLocation]);

  return [settings, setSettings];
}

export default useSettings;
