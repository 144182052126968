import Axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/build-hash`;
async function getBuildHash() {
    const result = await Axios.get(apiUrl);
    return result.data;
}

async function getOriginalBuildHash(setOriginalHash: Function) {
    setOriginalHash(await getBuildHash());
}

async function getNewBuildHash(setNewHash: Function) {
    setNewHash(await getBuildHash());
}

export {
    getOriginalBuildHash,
    getNewBuildHash,
    getBuildHash
}
