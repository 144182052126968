import moment from "moment-timezone";

const timeFormatStrings = {
    'withSeconds': "h:mm:ss a",
    'withoutSeconds': "h:mm a"
};

function getDateTime(showSeconds: boolean = false, tzid: string = "America/New_York") {

    if (!tzid) return ["", ""];
    return [
        moment()
            .tz(tzid)
            .format("dddd, MMMM Do YYYY"),
        moment()
            .tz(tzid)
            .format(timeFormatStrings[showSeconds ? 'withSeconds' : 'withoutSeconds'])
    ];
}

export default getDateTime;